:root {
  --primary: #00599c;
  --sidebarbg: #003c69;
  --authBg: #f4faff;
  --white: #ffffff;
  --orange: #ff6636;
}

/* ALL SIDEBAR CLASSES TO CHANGE style*/
.sidebar aside.ant-layout-sider.ant-layout-sider-dark {
  background: var(--sidebarbg) !important;
}
.sidebar .ant-menu {
  background-color: var(--sidebarbg);
  color: var(--textgraycolor);
  padding-bottom: 50px;
}
/* .sidebar .ant-layout {
  width: 100% !important;
} */
.sidebar .ant-menu-submenu-title {
  color: #000 !important;
}

.sidebar .ant-menu .ant-menu-item-selected {
  background-color: var(--white) !important;
  color: var(--sidebarbg) !important;
  margin-left: 10px;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.sidebar .ant-layout-header {
  padding-inline: 0;
}
.sidebar .ant-menu-item:hover {
  background-color: var(
    --sidebarbg
  ) !important; /* when hovering over on an item */
}
.sidebar .ant-menu-item-selected:hover {
  background-color: var(--white) !important; /* when hovering over on an item */
}
.sidebar li.ant-menu-item:active {
  background-color: var(--bs-white) !important;
}
.sidebar .ant-menu .ant-menu-item-selected:active {
  background-color: var(--sidebarbg) !important;
}

.sidebar .ant-menu .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: var(--sidebarbg) !important;
}
.sidebar .ant-menu-submenu .ant-menu-inline {
  background: var(--bs-white) !important;
  border-radius: 10px !important;
  /* margin:0px 15px 0px 15px !important; */
}
.ant-menu-submenu-popup .ant-menu .ant-menu-item {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  background-color: var(--bs-white);
  color: #000;
}
.ant-menu-submenu-popup
  .ant-menu
  .ant-menu-submenu
  .ant-menu-submenu-title
  .ant-menu-title-content
  p,
.ant-menu-submenu-arrow {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  background-color: var(--bs-white);
  color: #000 !important;
}
.ant-layout-sider-collapsed {
  width: 50px !important;
  min-width: 50px !important;
}
.ant-menu-submenu-popup .ant-menu .ant-menu-item-selected {
  background-color: var(--sidebarbg);
}
.ant-menu .ant-menu-sub {
  background-color: var(--bs-white) !important;
}
/* .ant-menu .ant-menu-sub li{
  padding-left: 21px !important;
} */
/* .ant-menu-submenu-popup .ant-menu .ant-menu-item:hover{
  background-color: var(--sidebarbg) !important;
}
.Nested_report .ant-menu-submenu-title{
  padding-left: 0px !important;
}
.Nested_report  .ant-menu .ant-menu-sub .ant-menu-inline{
  margin: 0px !important;
} */
/* CUSTOM SCROLLBAR */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #c8ced4;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--sidebarbg);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ant-select-selector {
  border-radius: 0px !important;
}

.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(
    --orange
  ) !important; /* Change the background color to green when checked */
  border: none !important;
  border-radius: 0px;
}
.ant-select-selector {
  border-radius: 0px !important;
}
.ant-checkbox-inner::after {
  border-color: white; /* Change the tick mark color to white */
}
.citySlider .slick-list .slick-slide {
  padding: 0 10px;
  text-align: center;
  /* @apply flex justify-center items-center */
  /* width: 280px !important; */
}
.ant-collapse-content-box {
  padding: 0px !important;
}
.nestedCollaps {
  border-radius: 0px !important;
  background-color: white !important;
  border: none;
}

.trading-img .ant-image {
  width: 100% !important;
}
.slick-prev {
  display: none !important;
}
.slick-next {
  display: none !important;
}
.ant-modal-content  {
  width: 648px !important;
  border-radius: 0px !important;

}